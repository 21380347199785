import { combineReducers, Middleware, StoreEnhancer, createStore, compose, applyMiddleware } from "redux";
import { firebaseReducer, reactReduxFirebase, authIsReady } from "react-redux-firebase";
import firebase from "firebase";
import FirebaseConfig from "../../config/firebase";

export const reducers = combineReducers({
	firebase: firebaseReducer
});

export interface StoreState {
	firebase: any;
}

export const initStore = (initialState = {}, onComplete: () => void) => {
	const middleware: Middleware[] = [];
	const enhancers: StoreEnhancer[] = [];

	if (!firebase.apps.length) {
		firebase.initializeApp(FirebaseConfig);
	}

	const store = createStore(
		reducers,
		initialState,
		compose(
			reactReduxFirebase(firebase, {
				userProfile: "users",
				enableRedirectHandling: false
			}),
			applyMiddleware(...middleware),
			...enhancers
		)
	);

	authIsReady(store, "firebase").then(() => {
		onComplete();

		// persistStore(store, {
		//     storage: AsyncStorage
		// }, onComplete);
	});

	return store;
};

