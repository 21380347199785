import React, { Component } from "react";
import { Spin } from 'antd';

import { LoadingStyle } from "./loadingStyle";

class LoadingPage extends Component {
	render() {
		return (
			<LoadingStyle>
				<Spin size="large" />
			</LoadingStyle>
		);
	}
}

export default LoadingPage;