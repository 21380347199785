import React, { Component } from "react";

import 'antd/dist/antd.css';
import "./App.css";

import { Provider } from "react-redux";
import { Store } from "redux";
import { Layout } from "antd";

import { ThemeProvider } from "./styles/styled-components";
import { Theme } from "./styles/theme";
import { initStore } from "./redux/reducers";
import { BrowserRouter, Switch, Route } from "react-router-dom";
import Routes from './routes';
import NotFoundPage from "./pages/notFound/notFound";

export interface AppProps { }
class App extends Component<AppProps> {
	store: Store;

	constructor(props: AppProps) {
		super(props);

		this.store = initStore({}, () => {

		});
	}

	_renderRoutes = () => {
		return (
			<Layout>
				<Switch>
					{Routes.public.map((route, index) => <Route key={`private-${index}`} exact={route.exact} path={route.route} component={route.component} />)}
					<Route component={NotFoundPage} />
				</Switch>
			</Layout>

		);
	}

	render() {
		return (
			<ThemeProvider theme={Theme}>
				<Provider store={this.store}>
					<BrowserRouter>
						{this._renderRoutes()}
					</BrowserRouter>
				</Provider>
			</ThemeProvider>
		);
	}
}

export default App;