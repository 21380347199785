import styled from "./styled-components";
import { mediaQueries } from "./media-queries";

const Button = styled.a`
	display: flex;
	justify-content: center;
	align-items: center;
	padding: 5px 20px;
	height: 40px;
	background-color: ${props => props.theme.primaryColor};
	color: white;
	border-radius: 5px;
	transition: all 150ms ease-in-out;

	&:hover {
		background-color: ${props => props.theme.primaryColorDark};
		color: white;
	}
`;

export {
	Button
};