import React, { Component } from "react";

import { Row, Col } from "antd";

import { InvitePageProps } from "./inviteContainer";
import NotFoundPage from "../notFound/notFound";
import { InvitePageStyle } from "./inviteStyle";
import logo from "../../assets/img/logo.png";
import app from "../../assets/img/app.png";
import appStore from "../../assets/img/app-store.png";
import { Button } from "../../styles/global";
import LoadingPage from "../loading/loading";
import { firebaseConnect } from "react-redux-firebase";
import { compose } from "redux";

class InvitePage extends Component<InvitePageProps> {
	removed: boolean = false;
	accepted: boolean = false;

	componentDidUpdate(prevProps: InvitePageProps) {
		const { firebase, inviteLoaded, invite, match: { params: { type, inviteId } } } = this.props;

		if (inviteLoaded && invite && type === "decline" && !this.removed) {
			// Remove invite object
			firebase.remove(`/invites/${inviteId}`, () => {
				this.removed = true;
			});
		}

		if (inviteLoaded && invite && type === "accept" && !this.accepted) {
			firebase.update(`/invites/${inviteId}`, {
				accepted: true
			}, () => {
				this.accepted = true;
			});
		}
	}

	_renderTxt = (type: string) => {
		const { invite } = this.props;
		const { invitedByFirstName, invitedByLastName, email } = invite;

		switch (type) {
			case "accept":
				return (
					<React.Fragment>
						<p>We have successfully received your confirmation for the task asked by {invitedByFirstName} {invitedByLastName} and you are almost good to start.</p>
						<p><strong>What's next?</strong></p>
						<ol>
							<li>Download the app from the Apple App Store</li>
							<li>Create an account with your email: <strong>{email}</strong></li>
							<li>Get the job done!</li>
						</ol>
					</React.Fragment>

				);
			case "decline":
				return (
					<React.Fragment>
						<p>We have successfully received your <strong style={{ color: "red" }}>decline</strong> for the task asked by {invitedByFirstName} {invitedByLastName}.</p>
						<p><strong>No problem, you probably have your reasons.</strong></p>
						<p>But if you're working on a project and you're wondering if other Remarkable Employees like you already did it, just download the app and find out how they got the job done.</p>
						<p><strong>What's next?</strong></p>
						<ol>
							<li>Download the app from the Apple App Store</li>
							<li>Create an account with your email: <strong>{email}</strong></li>
							<li>Get the job done!</li>
						</ol>
					</React.Fragment>
				);
		}
	}

	render() {
		const { inviteLoaded, invite, match: { params: { type } } } = this.props;

		if (!inviteLoaded) {
			return <LoadingPage />;
		}

		if (!invite) {
			return (
				<NotFoundPage />
			);
		}

		const { firstName, lastName } = invite;

		return (
			<InvitePageStyle>
				<Row gutter={32}>
					<Col span={24} className="logo">
						<img src={logo} />
					</Col>
				</Row>
				<Row gutter={32} className="contentRow">
					<Col xs={24} md={{ span: 9, offset: 3 }}>
						<h1>Thanks<br />{firstName} {lastName}</h1>
						{this._renderTxt(type)}
						<div className="buttons">
							<a style={{ display: "inline-block" }} href="https://itunes.apple.com/us/app/attitude-io/id1242265161">
								<img src={appStore} />
							</a>
							<Button href="http://attitude.io">About Attitude</Button>
						</div>

					</Col>
					<Col xs={24} md={12}>
						<img src={app} className="app" />
					</Col>
				</Row>
			</InvitePageStyle>

		);
	}
}

export default compose(
	firebaseConnect()
)(InvitePage);