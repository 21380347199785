import React, { Component } from "react";
import { NotFoundStyle } from "./notFoundStyle";

class NotFoundPage extends Component {
	render() {
		return (
			<NotFoundStyle>
				<h1>404</h1>
				<h2>Page not found</h2>
			</NotFoundStyle>
		);
	}
}

export default NotFoundPage;