import React from "react";
import { compose } from "redux";
import { withRouter, RouteComponentProps } from "react-router";
import InvitePage from "./invite";
import { StoreState } from "../../redux/reducers";
import { connect } from "react-redux";
import { firebaseConnect, isLoaded } from "react-redux-firebase";

export interface InvitePageContainerProps {
	firebase: any;
}

export type WithReduxStateProps = ReturnType<typeof mapStateToProps>;
const mapStateToProps = (state: StoreState) => {
	const fbInvites = state.firebase.data.invites;

	return {
		invite: fbInvites ? fbInvites[Object.keys(fbInvites)[0]] : null,
		inviteLoaded: isLoaded(fbInvites)
	};
};

const withRedux = connect(mapStateToProps);

const withFirebaseConnect = firebaseConnect(
	(props: RouteComponentProps<{ inviteId: string; type: string; }>) => {
		const { match: { params: { inviteId } } } = props;

		return [
			{
				type: "once",
				path: `invites/${inviteId}`
			}
		]
	}
)

export const InvitePageContainer = compose<React.ComponentClass>(
	withRouter,
	withRedux,
	withFirebaseConnect
)(InvitePage);



export type InvitePageProps =
	InvitePageContainerProps &
	WithReduxStateProps &
	RouteComponentProps<{ inviteId: string; type: string; }>;