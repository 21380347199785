import styled from "../../styles/styled-components";
import { mediaQueries } from "../../styles/media-queries";
import { Button } from "../../styles/global";

const InvitePageStyle = styled.div`
	padding: 30px; 

	@media ${mediaQueries.tablet} {
		display: flex;
		flex-direction: column;
		justify-content: center;
		align-items: center;
		min-height: 100vh;
	}

	.logo {
		display: flex;
		justify-content: center;
		padding-bottom: 50px;
	}

	img.app {
		max-height: 750px;
		max-width: 100%;
		margin-top: 30px;

		@media ${mediaQueries.tablet} {
			margin-top: 0;
		}
	}

	.contentRow {
		@media ${mediaQueries.tablet} {
			display: flex;
			align-items: center;
		}		
	}

	.buttons {
		display: flex;
		align-items: center;
		padding-top: 20px;
	
		${Button} {
			margin-left: 20px;
		}
	}

	
`;

export {
	InvitePageStyle
};