import HomePage from './pages/home/Home';
import { InvitePageContainer } from "./pages/invite/inviteContainer";

export interface RouteType {
	route: string;
	component: any;
}

export interface RoutesType {
	public: RouteType[];
	private: RouteType[];
}

const Routes = {
	public: [
		// {
		// 	route: "/",
		// 	exact: true,
		// 	component: HomePage
		// },
		{
			route: "/invite/:type(accept|decline)/:inviteId",
			exact: true,
			component: InvitePageContainer
		}
	],
	private: []
}

export default Routes;