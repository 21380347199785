import styled from "../../styles/styled-components";

const NotFoundStyle = styled.div`
	display: flex;
	flex-direction: column;
	min-height: 100vh;
	justify-content: center;
	align-items: center;

	h1 {
		margin-bottom: 0;
	}
`;

export {
	NotFoundStyle
};