import styled from "../../styles/styled-components";

const LoadingStyle = styled.div`
	display: flex;
	min-height: 100vh;
	justify-content: center;
	align-items: center;
`;

export {
	LoadingStyle
};