export interface ThemeInterface {
	primaryColor: string;
	primaryColorDark: string;
};

const Theme: ThemeInterface = {
	primaryColor: "#007AFF",
	primaryColorDark: "#28517A"
};

export {
	Theme
}